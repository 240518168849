import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Gist from 'react-gist';
import '../assets/css/style.css';
import CodeHighlighter from "../components/CodeHighlighter"
import { curlCommand } from "."

const IndexPage = () => (
  <Layout>
    <SEO description="Emailkick give you a simplified API for application-driven software to send emails to your customers powered by Amazon SES" title="Reliable, simple API for your emails powered by Amazon SES" />
    <div className=" hero-back align-center">
      <div className="max-w-6xl mx-auto pb5 pt4">


      <div className="flex flex-wrap items-center feat-blu">
        
        <div className="md-col-5 pr3">
          <h1 className="mb3 font-medium text-4xl leading-tight">APIs that enable you to send dynamic email effortlessly.</h1>
          <h3 className="display text-gray-600 text-xl pb2 mb3 leading-normal">Simplified Email API for application-driven software powered by Amazon SES</h3>
          <div className="hero-buttons mt1">
            <a href='https://app.emailkick.com/register' rel="nofollow" className=" bg-red-500 hover:bg-red-600 text-white strong px-8 py-4 text-lg font-medium rounded-xl  duration-100 shadow-lg">Get started for free</a>

            {/* <a className="ed-btn primary-cta mr3">Start Sending</a> */}
            {/* <a className="sec-cta">Watch Video</a> */}
          </div>

        </div>

        <div className="md-col-7">
          {/* <Gist id={'e8478428980695c900ce00d51bcb3e8f'} file={'gistfile1.txt'} /> */}
          <img className='col-12' src={'/front-img.png'} />

        </div>


        
      </div>


        <div className="hero text-center">

         

         
        </div>  



      
      </div>

      
    </div>

 


    <section className="feat-section pb-8" >
      <div className="max-width-4 mx-auto">
        <div className="flex flex-wrap items-center feat-blu p2">
        
          <div className="md-col-5 pr3">
            <h2 className="mb3 display strong xhuge line-height-2  highlight-text">Send an email with a few lines of code</h2>
            <h3 className="quiet display line-height-3 mb2">Get up and running today. We have a robust set of APIs and comprehensive documentation so you can spend less time adding email into your app and more time building cool stuff.</h3>
          </div>

          <div className="md-col-7">
            {/* <Gist id={'e8478428980695c900ce00d51bcb3e8f'} file={'gistfile1.txt'} /> */}
            {/* <img className='col-12' src={'/emails-04.png'} /> */}
            <div className="w-full rounded-2xl bg-white bg-opacity-90 shadow-lg border border-gray-200 relative z-20 glassbox-non-radius">
              <div className="bg-gray-50 px-8 py-3 text-base border-b bg-opacity-75 border-gray-200 rounded-t-2xl"><code className="text-gray-400">sendEmail.code</code></div>
              <div className="py-2 md:px-6 px-2 text-xs md:text-base rounded-2xl mx-auto" >
                <CodeHighlighter code={curlCommand} />
              </div>

            </div>

          </div>


          
        </div>
      </div>
    </section>

       <section className="feat-section py4" style={{ backgroundColor:'#f7f9fd' }}>
      <div className="max-width-4 mx-auto">
        <div className="flex flex-wrap items-center py3 px4 center justify-center">

          <h2 className="center mb3 display strong huge ">Get your message to the right person at the right time with trusted infratructure by Amazon SES</h2>
          <h3 className="quiet display line-height-3 mb2">Start sending transactional emails from your application in no time. EmailKick API is extremely simple to use, and with easy to use no-code editor it's so easy to get started!</h3>
        </div>
         
      </div>
    </section>



    <section className="feat-section" >
      <div className="max-width-4 mx-auto">
        <div className="flex flex-wrap items-center feat-pin py1 px0">

          <div className="md-col-7">
            <img className='col-12' src={'/emails-03.png'} />
          </div>


          <div className="md-col-5">
            <h2 className="mb3 display strong xhuge line-height-2 highlight-text">Design Good Looking Emails</h2>
            <h3 className="quiet display line-height-3 mb2">Design beautiful web and mobile emails without writing any code. Our drag and drop builder gives you total freedom, letting you build pixel-perfect, responsive designs.</h3>
          </div>
        
        </div>
      </div>
    </section>








     <section className="feat-section" style={{ marginTop: '80px' , }}>
      <div className="max-width-4 mx-auto">
        <div className="row feat-pnk p4 center">
        
            <h2 className="mb2 display strong xhuge line-height-2  highlight-text">Seamlessly Connect to AWS SES in minutes</h2>
            <h3 className=" display line-height-3 mb2">Cut your email delivery cost to a fraction using Amazon Web Service Simple Email Service. It takes just seconds to connect.</h3>
      
            <div className="cta-bottom">
              <a href='https://app.emailkick.com/register' rel="nofollow" className=" bg-red-500 hover:bg-red-600 text-white strong px-8 py-4 text-lg font-medium rounded-xl  duration-100 shadow-lg">Get started for free</a>
            </div>
        </div>
      </div>
    </section>




  </Layout>
)

export default IndexPage
